import './NewsTag.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import React, { FunctionComponent } from 'react'
import { HeadProps } from 'gatsby'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'
import { GenericPageProps, IEasySoftwareNewsTag } from 'Shared/queries/page-queries'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import PuxRepeater from 'Root/Shared/components/PuxRepeater/PuxRepeater'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import EasySoftwareBreadcrumbs from 'Root/Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'

const NewsTag: FunctionComponent<GenericPageProps<IEasySoftwareNewsTag>> = (props) => {
  const pageData = props.pageContext.pageData
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const repeaterData = props.pageContext.newsRepeater

  const breadcrumbsData = props.pageContext.breadcrumbs

  console.log(props.pageContext.newsRepeater)
  return (
    <Layout localizedPath={pageData.localization?.localizations} footerData={footerData} headerData={headerData} pageContentItemId={pageData.contentItemId}>
      <div className='NewsTag'>
        <EasySoftwareBreadcrumbs items={breadcrumbsData} />
        <div className='Container'>
          <div className='NewsTag-title'>
            {pageData.newsTagContent?.html ? (
              <EasySoftwareEditorContainer wysiwygId={pageData.contentItemId + '-perex'} pageContentItemId={pageData.contentItemId} content={pageData.newsTagContent} editorPath={`newsCategoryContent,html`} />
            ) : (
              <div className='Wysiwyg Wysiwyg--spaced u-textCenter'>
                <h1>{pageData.displayText}</h1>
              </div>
            )}
          </div>
          {repeaterData ? (
            <div className="NewsTag-grid">
              <section className="Section">
                <div className="Container">
                  <div className="Section-grid">
                    <div className="WidgetContainer WidgetContainer-padding WidgetContainer--100 Justify">
                      <PuxRepeater {...repeaterData} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default NewsTag

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareNewsTag>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}